import React, { useState } from "react";
import Markdown from "../Markdown/Markdown";
import { Separator } from "@/components/ui/separator";
import {
  AiOutlineRobot,
  AiOutlineLike,
  AiOutlineDislike,
  AiFillLike,
  AiFillDislike,
} from "react-icons/ai";
import { IoIosLink } from "react-icons/io";
import { sendFeedback } from "../../services/api/ManagerService";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import toast from "react-hot-toast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Textarea } from "../ui/textarea";
import BaseButton from "../Buttons/BaseButton";

function NegativeFeedbackDialog({ onSendFeedback }) {
  const [reason, setReason] = useState("");

  return (
    <Dialog>
      <DialogTrigger>
        <AiOutlineDislike className="h-4 w-4 cursor-pointer" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Expected Answer</DialogTitle>
          <DialogDescription>
            <div className="mt-4">
              <Textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
              <div className="mt-2 flex justify-end">
                <BaseButton
                  value={"Send Feedback"}
                  onClick={() => onSendFeedback(reason)}
                />
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

function BotMessageV2({ query, message, chatbots, documents, onSelected }) {
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [feedback, setFeedback] = useState(false);

  const handleSendPositiveFeedback = async () => {
    setLiked(true);
    setDisliked(false);
    const payload = {
      user_sub: localStorage.getItem("sub"),
      query: query,
      answer: message,
      is_correct: true,
      source: "chat",
      chatbot: chatbots.join(","), // chatbot is an array
    };
    if (feedback) return;

    const res = await sendFeedback(chatbots[0], payload); // work around because the endpoint need the chatbot name, but we are sending the payload to store multiple chatbots
    if (res) {
      toast.success(
        "We received your feedback! Thanks to help Chatto to perform better. 😀"
      );
      setFeedback(true);
    }
  };

  const handleSendNegativeFeedback = async (reason) => {
    setLiked(false);
    setDisliked(true);
    const payload = {
      user_sub: localStorage.getItem("sub"),
      query: query,
      answer: message,
      expected_answer: reason,
      is_correct: false,
      source: "chat",
      chatbot: chatbots.join(","), // chatbot is an array
    };
    if (feedback) return;

    const res = await sendFeedback(chatbots[0], payload); // work around because the endpoint need the chatbot name, but we are sending the payload to store multiple chatbots
    if (res) {
      toast.success(
        "We received your feedback! Thanks to help Chatto to perform better. 😀"
      );
      setFeedback(true);
    }
  };

  return (
    <div className="bg-gray-200 p-5 rounded-lg">
      <Markdown text={message} />

      <Separator className="my-4 bg-gray-300" />
      <div className="flex items-center gap-2">
        {chatbots.map((chatbot) => (
          <Button
            key={chatbot}
            className="bg-transparent border text-gray border-primary flex gap-1 hover:bg-primary hover:text-white"
            onClick={() => onSelected(chatbot)}
          >
            <AiOutlineRobot className="h-4 w-4" />
            <span className="text-xs">Chat with {chatbot}</span>
          </Button>
        ))}

        {documents.length > 0 && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <IoIosLink className="h-4 w-4" />
              </TooltipTrigger>
              <TooltipContent>
                <h3 className="text-xs">Sources:</h3>
                <ul>
                  {documents.map((doc, i) => (
                    <li key={i} className="text-xs">
                      {doc}
                    </li>
                  ))}
                </ul>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}

        <div className="flex items-center justify-end gap-2 w-full z-50">
          {liked ? (
            <AiFillLike
              className="h-4 w-4 cursor-pointer text-primary"
              onClick={() => setLiked(false)}
            />
          ) : (
            <AiOutlineLike
              className="h-4 w-4 cursor-pointer"
              onClick={handleSendPositiveFeedback}
            />
          )}

          {disliked ? (
            <AiFillDislike
              className="h-4 w-4 cursor-pointer text-red-500"
              onClick={() => setDisliked(false)}
            />
          ) : (
            <NegativeFeedbackDialog
              onSendFeedback={(r) => handleSendNegativeFeedback(r)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default BotMessageV2;
