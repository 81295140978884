import React, { useEffect, useState, useRef } from "react";
import PageLayout from "../PageLayout";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { IoIosSend } from "react-icons/io";
import { PiSparkle } from "react-icons/pi";
import { AiOutlineClear } from "react-icons/ai";
import toast from "react-hot-toast";
import UserMessageV2 from "@/components/ChatMessage/UserMessageV2";
import BotMessageV2 from "@/components/ChatMessage/BotMessageV2";
import DialogSelectChatbot from "@/components/Dialog/DialogSelectChatbot";
import { fetchChatbotAnswer } from "../../services/api/ChatService";
import { fetchTaskStatus } from "../../services/api/TaskService";
import { addAnalyticsQueryUsage } from "../../services/api/AnalyticsService";
import { useQueryCountState } from "../../states/useStates";
import { delay } from "../../services/utils";
import SpinnerChat from "@/components/Spinner/SpinnerChat";
import DialogSelectTool from "@/components/Dialog/DialogSelectTool";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { GoRocket } from "react-icons/go";
import { IoIosClose } from "react-icons/io";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

function ChatV2() {
  const [messages, setMessages] = useState([]);
  const listRef = useRef(null);
  const [lastMessage, setLastMessage] = useState();
  const [chatbot, setChatbot] = useState("");
  const [tool, setTool] = useState("");
  const [input, setInput] = useState("");
  const [asyncJobID, setAsyncJobID] = useState();
  const [queryCount, setQueryCount] = useQueryCountState();
  const [isTyping, setIsTyping] = useState(false);
  const [openExplanation, setOpenExplanation] = useState(true);

  const sendQueryAnalytics = (result) => {
    const chatbot_payload = (chatbot) => {
      if (Array.isArray(chatbot)) {
        return chatbot.join(",");
      } else {
        return chatbot;
      }
    };

    const payload = {
      user_sub: localStorage.getItem("sub"),
      chatbot_name: chatbot_payload(chatbot || result.index),
      company_id: localStorage.getItem("company_id"),
      query: input,
      answer: result.answer,
      total_token: result.total_tokens,
      chatbot_model: result.chatbot_model,
      source: "chat",
    };

    addAnalyticsQueryUsage(payload);
  };

  const getAsyncResponse = async (task_id, chatbot) => {
    const res = await fetchTaskStatus(task_id);
    if (res && res.completed === true) {
      console.log("res", res.result);
      const botMessage = {
        role: "bot",
        message: res.result.answer,
        isLoading: false,
        chatbot: Array.isArray(res.result.index)
          ? res.result.index
          : [res.result.index] || chatbot,
        documents: res.result.documents || [],
        query: input,
      };

      if (!botMessage.message) {
        toast.error(
          "Something went wrong during the chat. Please retry! If the error persists contact the customer service."
        );
      } else {
        setLastMessage(botMessage);
      }
      setIsTyping(false);

      if (Object.keys(res.result).includes("total_tokens")) {
        sendQueryAnalytics(res.result);
      }
    } else {
      // Wait for a while and make the API call again
      await delay(1000); // You can adjust the delay as needed
      await getAsyncResponse(task_id, chatbot); // Recursive call
    }
  };

  const handleSendMessage = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      if (tool !== "" && !chatbot) {
        toast.error("To use the tool, please select a chatbot first!");
        return;
      }

      setMessages([...messages, { role: "user", message: input }]);

      const payload = {
        chatbot_name: chatbot,
        company_id: localStorage.getItem("company_id"),
        tool: tool,
        query: input,
        history: messages,
      };

      setIsTyping(true);

      try {
        const res = await fetchChatbotAnswer(payload).catch((err) => {
          toast.error(
            "Something went wrong during the chat. Please retry! If the error persists contact the customer service."
          );
          setIsTyping(false);
        });

        if (res) {
          setAsyncJobID(res.job_id);
          getAsyncResponse(res.job_id, chatbot);
          setQueryCount(queryCount + 1);
        }
      } catch {
        toast.error(
          "Something went wrong during the chat. Please retry! If the error persists contact the customer service."
        );
        setIsTyping(false);
      }
      setInput("");
      e.target.value = "";
      e.target.selectionStart = 0;
      e.target.selectionEnd = 0;
      e.preventDefault();
    } else if (e.key === "Enter" && e.shiftKey) {
      setInput(input + "\n");
      e.preventDefault();
    }
  };

  const handleSendMessageOnClick = async (e) => {
    if (input) {
      setMessages([...messages, { role: "user", message: input }]);

      if (tool !== "" && !chatbot) {
        toast.error("Please select a chatbot first!");
        return;
      }

      const payload = {
        chatbot_name: chatbot,
        company_id: localStorage.getItem("company_id"),
        tool: tool,
        query: input,
        history: messages,
      };

      try {
        const res = await fetchChatbotAnswer(payload).catch((err) => {
          toast.error(
            "Something went wrong during the chat. Please retry! If the error persists contact the customer service."
          );
          setIsTyping(false);
        });

        if (res) {
          setIsTyping(true);
          setAsyncJobID(res.job_id);
          getAsyncResponse(res.job_id, chatbot);
          setQueryCount(queryCount + 1);
        }
      } catch {
        toast.error(
          "Something went wrong during the chat. Please retry! If the error persists contact the customer service."
        );
      }

      setInput("");
      e.target.value = "";
      e.target.selectionStart = 0;
      e.target.selectionEnd = 0;
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (lastMessage) {
      setMessages([...messages, lastMessage]);
      setLastMessage();
    }
  }, [lastMessage]);

  useEffect(() => {
    if (chatbot) {
      toast.success("Chatbot loaded successfully!");
    }
  }, [chatbot]);

  useEffect(() => {
    listRef.current?.lastElementChild?.scrollIntoView();
  }, [messages]);

  return (
    <PageLayout>
      {/* sonner */}
      {isTyping && <SpinnerChat />}
      <div className="h-screen w-full flex justify-center">
        <div className="grid grid-rows-6 h-full w-3/4">
          {/* messages zone */}
          <div className="row-span-5 py-5 relative">
            {openExplanation && messages.length === 0 && (
              <div className="absolute w-full h-5/6 flex items-center justify-center">
                <Alert className="w-fit opacity-80">
                  <div className="flex justify-between mb-2">
                    <div className="flex gap-2">
                      <GoRocket className="h-4 w-4" />
                      <AlertTitle className="font-bold text-xl text-primary">
                        Let's chat!
                      </AlertTitle>
                    </div>
                    <IoIosClose
                      onClick={() => setOpenExplanation(false)}
                      className="cursor-pointer"
                    />
                  </div>

                  <AlertDescription>
                    <p>Type in the text box to start a conversation.</p>
                    <p>
                      Chatto will try to understand where to find the
                      informations inside his knowledge base.
                    </p>
                    <p className="mt-3">
                      Do you want to talk with a particular chatbot? No problem!
                      Just click on{" "}
                      <span className="font-bold">Select Chatbot</span> and
                      select it.
                    </p>

                    <p className="mt-3">
                      Each chatbot has its own tools. You can select the tool
                      you want to use by clicking on{" "}
                      <span className="font-bold">Select Tool</span>.
                    </p>
                  </AlertDescription>
                </Alert>
              </div>
            )}
            <div
              className="overflow-auto h-full flex flex-col gap-5 no-scrollbar"
              ref={listRef}
            >
              {messages.map((message, i) => {
                return message.role === "user" ? (
                  <div key={i} className="flex justify-end">
                    <UserMessageV2 message={message.message} />
                  </div>
                ) : (
                  <div key={i} className="flex">
                    <BotMessageV2
                      query={message.query}
                      message={message.message}
                      chatbots={message.chatbot}
                      documents={message.documents}
                      onSelected={(chatbot) => setChatbot(chatbot)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {/* chat zone */}
          <div className="w-full flex items-center py-1 gap-2 flex-col">
            {/* options */}
            <div className="w-full p-1 flex items-center gap-5">
              <DialogSelectChatbot
                onChatbotSelect={(v) => setChatbot(v)}
                currentChatbot={chatbot}
              />
              {!chatbot ? (
                <div
                  className="flex items-center text-sm w-fit gap-2 opacity-30 cursor-pointer hover:opacity-100"
                  onClick={() => toast.error("Select a chatbot first!")}
                >
                  <PiSparkle />
                  <span>Select tool</span>
                </div>
              ) : (
                <DialogSelectTool
                  onToolSelect={(v) => setTool(v)}
                  currentTool={tool}
                  chatbot={chatbot}
                />
              )}
            </div>

            {/* input */}
            <div className="w-full flex relative">
              <div className="w-full">
                <Textarea
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={(e) => handleSendMessage(e)}
                  placeholder="Ask me anything..."
                  className=" min-h-[60px] max-h-[80px] w-full bg-transparent pr-20 resize-none focus:resize-y focus:outline-none focus:ring-transparent focus:border-primary border-gray-300"
                  autoFocus // Add this line to set the cursor on top
                />
              </div>
              <div className="h-full flex items-center absolute right-5 gap-2">
                <Button
                  variant="outline"
                  size="icon"
                  className="bg-transparent hover:bg-primary text-primary hover:text-white"
                  data-tooltip-id="clear-tooltip"
                  data-tooltip-content="Clear Chat and History"
                  onClick={() => setMessages([])}
                >
                  <AiOutlineClear className="h-4 w-4" />
                </Button>
                <Button
                  variant="outline"
                  size="icon"
                  className="bg-transparent hover:bg-primary text-primary hover:text-white"
                  data-tooltip-id="send-tooltip"
                  data-tooltip-content="Send"
                  onClick={(e) => handleSendMessageOnClick(e)}
                >
                  <IoIosSend className="h-4 w-4" />
                </Button>

                <ReactTooltip id="send-tooltip" />
                <ReactTooltip id="clear-tooltip" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default ChatV2;
