import React, { useState, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import AuthService, { forgotPassword } from "../../services/api/AuthService";
// import Cirlces from "./img/circles.svg";
import Logo from "./img/Logo-extended.png";
import BaseButton from "../../components/Buttons/BaseButton";
import { AccountContext } from "../../services/AccountService";
import toast from "react-hot-toast";
import { Input } from "@/components/ui/input";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardDescription,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";

function Login({ isMobile }) {
  const navigate = useNavigate();
  const { login } = useContext(AccountContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [firstLogin, setFirstLogin] = useState(false);

  const handleLogin = () => {
    login(email, password)
      .then((res) => {
        if (res.data.first_login) {
          setFirstLogin(true);
          setOldPassword(password);
        }
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Email or password not valid.");
      });
  };

  const validateNewPassword = () => {
    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (strongPasswordRegex.test(password)) {
      if (password === repeatedPassword) {
        return true;
      } else {
        toast.error("Passwords don't match");
      }
    } else {
      toast.error("Passwords don't match");
    }
  };

  const handleChangePassword = async () => {
    const isValid = validateNewPassword();
    if (isValid) {
      const res = await AuthService.put("/change-password", {
        email: email,
        old_password: oldPassword,
        new_password: password,
      });

      if (res.status === 200) {
        handleLogin();
      }
    }
  };

  const handleForgotPassword = async () => {
    if (email === "") {
      toast.error("Insert your email address");
      return;
    }

    const res = await forgotPassword(email).catch((e) => console.log(e));

    if (res) {
      toast.success(
        "An email with a temporary password has been sent to your email address."
      );
    } else {
      toast.error("Something went wrong...");
    }
  };

  return (
    <>
      {isMobile ? (
        <div className="flex flex-col gap-16 justify-center items-center h-screen w-screen">
          <img src={Logo} alt="logo" className="max-h-16 w-auto px-5" />
          <Card className="min-w-[16rem] mb-10">
            {firstLogin ? (
              <>
                <CardTitle className="text-center text-3xl pt-4 pb-2">
                  Set new password
                  <span className="text-primary text-2xl">.</span>
                </CardTitle>
                <CardContent className="flex flex-col gap-4">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <span className="w-full border-t" />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <Label htmlFor="password">Password</Label>
                    <Input
                      id="password"
                      placeholder="New password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Label htmlFor="email">Repeated Password</Label>
                    <Input
                      id="email"
                      type="password"
                      placeholder="Repeat new password"
                      value={repeatedPassword}
                      onChange={(e) => setRepeatedPassword(e.target.value)}
                    />
                  </div>
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <span className="w-full border-t" />
                    </div>
                  </div>
                </CardContent>
                <CardFooter>
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <span className="w-full border-t" />
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="text-xs text-primary">
                      <span>Password should match the following criteria:</span>
                      <ul className="mt-2">
                        <li>At least 8 characters in total.</li>
                        <li>At least one lowercase letter.</li>
                        <li>At least one uppercase letter.</li>
                        <li>At least one digit.</li>
                        <li>At least one special character.</li>
                      </ul>
                    </div>
                    <Button onClick={handleChangePassword} className="w-full">
                      Change
                    </Button>
                  </div>
                </CardFooter>
              </>
            ) : (
              <>
                <CardHeader className="space-y-1">
                  <CardTitle className="text-4xl">
                    Login<span className="text-primary text-4xl">.</span>
                  </CardTitle>
                </CardHeader>
                <CardContent className="flex flex-col gap-4">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <span className="w-full border-t" />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <Label htmlFor="email">Email</Label>
                    <Input
                      id="email"
                      type="email"
                      placeholder="m@example.com"
                      value={email}
                      className="focus-visible:ring-primary focus:ring-primary"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Label htmlFor="password">Password</Label>
                    <Input
                      id="password"
                      type="password"
                      className="focus-visible:ring-primary focus:ring-primary"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </CardContent>
                <div className="mb-5">
                  <div className="flex flex-col items-center justify-center">
                    <div
                      className="text-grayCustom/60 underline text-xs mb-5 cursor-pointer self-end pr-6"
                      onClick={handleForgotPassword}
                    >
                      Forgot password?
                    </div>
                    <Button onClick={handleLogin} className="min-w-[10rem]">
                      Login
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Card>
        </div>
      ) : (
        <div className="bg-base h-screen grid grid-cols-5 overflow-hidden">
          <div className="bg-base col-span-2 relative flex justify-center items-center overflow-hidden">
            {/* <img
              src={Cirlces}
              alt="circle"
              className="absolute object-cover min-h-full min-w-full"
            /> */}
            <img src={Logo} alt="logo" className="max-h-20 w-auto px-5" />
          </div>
          <div className="flex justify-center items-center text-gray col-span-3 flex-col bg-white">
            {/* form */}
            <div className="w-2/4">
              {firstLogin ? (
                <>
                  <h2 className="font-bold text-5xl mb-5 w-full">
                    Set new password<span className="text-primary">.</span>
                  </h2>
                  <input
                    type="password"
                    className="p-3 w-full rounded border border-gray/20 outline-1 mb-5"
                    placeholder="New password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <input
                    type="password"
                    className="p-3 w-full rounded border border-gray/20 outline-1"
                    placeholder="Repeat password"
                    value={repeatedPassword}
                    onChange={(e) => setRepeatedPassword(e.target.value)}
                  />
                  <div className="mt-5 text-xs text-primary">
                    <span>Password should match the following criteria:</span>
                    <ul className="mt-2">
                      <li>At least 8 characters in total.</li>
                      <li>At least one lowercase letter.</li>
                      <li>At least one uppercase letter.</li>
                      <li>At least one digit.</li>
                      <li>At least one special character.</li>
                    </ul>
                  </div>

                  <div className="flex flex-col md:flex-row mb-5 mt-5 items-center justify-between">
                    <BaseButton value="Change" onClick={handleChangePassword} />
                  </div>
                </>
              ) : (
                <>
                  <h2 className="font-bold text-5xl mb-5">
                    Login<span className="text-primary">.</span>
                  </h2>
                  <input
                    type="text"
                    className="p-3 w-full rounded border border-gray/20 mb-5 outline-1"
                    placeholder="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    type="password"
                    className="p-3 w-full rounded border border-gray/20 outline-1"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="flex flex-col md:flex-row mb-5 mt-5 items-center justify-between">
                    <div
                      className="text-gray/50 hover:text-gray text-xs mb-5 md:mb-0 cursor-pointer"
                      onClick={handleForgotPassword}
                    >
                      Forgot password?
                    </div>
                    <BaseButton value="Sign In" onClick={handleLogin} />
                  </div>
                </>
              )}
            </div>
            {/* end - form */}
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
