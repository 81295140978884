import axios from "axios";

const UsersService = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_ENDPOINT + "/api/v1/users",
    headers: {
      "Content-Type": "application/json",
      "X-XSS-Protection": "X-XSS-Protection: 1; mode=block",
      "X-Frame-Options": "SAMEORIGIN",
      "Strict-Transport-Security": "max-age=10000000",
      "Content-Security-Policy": "default-src 'self'; child-src 'none';",
      "Referrer-Policy": "no-referrer",
      "X-Content-Type-Options": "nosniff",
      Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
    },
  };

  let instance = axios.create(defaultOptions);

  return instance;
};

export default UsersService();

const fetchQueryUsage = async () => {
  const res = await UsersService().get(`/me/metrics/query-usage`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("localAuthorization"),
    },
  });
  if (res.status === 200) {
    return res.data;
  }
};

const setFavoriteChatbot = async (chatbot_id) => {
  const res = await UsersService().post(`/me/favorite-chatbot`, {
    chatbot_id: chatbot_id,
  });
  if (res.status === 200) {
    return res.data;
  }
};

const getFavoriteChatbot = async () => {
  const res = await UsersService().get(`/me/favorite-chatbot`);
  if (res.status === 200) {
    return res.data;
  }
};

const fetchUserChatbots = async () => {
  const res = await UsersService().get(`/me/chatbots`);
  if (res.status === 200) {
    return res.data;
  }
};

const fetchUserChatbotsTools = async (chatbot_name) => {
  const res = await UsersService().get(`/me/chatbots/${chatbot_name}/tools`);
  if (res.status === 200) {
    return res.data;
  }
};

export {
  fetchQueryUsage,
  setFavoriteChatbot,
  getFavoriteChatbot,
  fetchUserChatbots,
  fetchUserChatbotsTools,
};
