import React, { useState, useEffect, useRef } from "react";
import ManagerService from "../../services/api/ManagerService";
import {
  fetchChatbotAnswer,
  fetchScorePrompt,
} from "../../services/api/PlaygroundService";
import { sendFeedback } from "../../services/api/ManagerService";
import BotMessage from "./BotMessage";
import UserMessage from "./UserMessage";
import PlaygroundTools from "../../components/Playground/PlaygroundTools";
import ChatInputPlayground from "../../components/ChatInput/ChatInputPlayground";
import BaseButton from "../../components/Buttons/BaseButton";
import {
  usePromptScoreState,
  useQueryCountState,
} from "../../states/useStates";
import { v4 as uuidv4 } from "uuid";
import { useRecoilValue } from "recoil";
import { companyConstraintsState, currentUserState } from "../../states/atoms";
import { useCurrentChatbotConfigState } from "../../states/useStates";
import { fetchTaskStatus } from "../../services/api/TaskService";
import { addAnalyticsQueryUsage } from "../../services/api/AnalyticsService";
import PageLayout from "../PageLayout";
import { delay } from "../../services/utils";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
function Playground() {
  const listRef = useRef(null);
  const [query, setQuery] = useState("");
  const [messages, setMessages] = useState([]);
  const [lastBotMessage, setLastBotMessage] = useState("");
  const [promptScore, setPromptScore] = usePromptScoreState();
  const [queryCount, setQueryCount] = useQueryCountState();
  const companyConstraints = useRecoilValue(companyConstraintsState);
  const [config, setConfig] = useCurrentChatbotConfigState();
  const [history, setHistory] = useState([]);
  const [asyncJobID, setAsyncJobID] = useState();
  const navigate = useNavigate();
  const user = useRecoilValue(currentUserState);

  const sendQueryAnalytics = (result) => {
    const payload = {
      user_sub: localStorage.getItem("sub"),
      chatbot_name: config.chatbot_name,
      company_id: localStorage.getItem("company_id"),
      query: query,
      token_query: result.token_query,
      token_answer: result.token_answer,
      cost_usd: result.cost_usd,
      chatbot_model: config.chatbot_model,
      source: "playground",
    };

    addAnalyticsQueryUsage(payload);
  };

  const getAsyncResponse = async (task_id, currentUUID) => {
    const res = await fetchTaskStatus(task_id);
    if (res && res.completed === true) {
      const botMessage = {
        id: currentUUID,
        role: "bot",
        message: res.result.response,
        isLoading: false,
        system_prompts: config.system_prompt,
      };
      setLastBotMessage(botMessage);

      sendQueryAnalytics(res.result);
    } else {
      // Wait for a while and make the API call again
      await delay(5000); // You can adjust the delay as needed
      await getAsyncResponse(task_id, currentUUID); // Recursive call
    }
  };

  const handleSendMessage = async (message) => {
    if (queryCount >= companyConstraints.max_daily_query) {
      toast.error(`You have reached the max number of daily queries quota.`);
      return;
    }

    if (query && query.length > 0) {
      if (config.id !== undefined) {
        const payload = {
          company_id: localStorage.getItem("company_id"),
          chatbot_name: config.chatbot_name,
          query: message,
          chatbot_model_provider: config.chatbot_model_provider,
          chatbot_model: config.chatbot_model,
          temperature: config.temperature,
          max_tokens: config.max_tokens,
          chain_type: config.chain_type.replace("-", "_"),
          system_prompt: config.system_prompt,
          history: messages,
        };

        const userMessage = {
          id: uuidv4(),
          role: "user",
          message: message,
          system_prompts: payload.system_prompt,
        };
        setMessages((oldMessages) => [...oldMessages, userMessage]);
        setHistory([...history, userMessage]);
        setQuery("");

        const currentUUID = uuidv4();
        setMessages((messages) => [
          ...messages,
          {
            id: currentUUID,
            role: "bot",
            message: "",
            isLoading: true,
            system_prompts: payload.system_prompt,
          },
        ]);

        try {
          const res = await fetchChatbotAnswer(payload).catch((err) =>
            setLastBotMessage({
              id: currentUUID,
              role: "bot",
              message:
                "Something went wrong during the chat. Please retry! If the error persists contact the customer service",
              isLoading: false,
              system_prompts: payload.system_prompt,
            })
          );

          if (res) {
            setAsyncJobID(res.job_id);
            getAsyncResponse(res.job_id, currentUUID);
            setQueryCount(queryCount + 1);
          }

          // const scorePayload = {
          //   query: message,
          //   answer: res.response,
          //   prompt: payload.system_prompt,
          // };
          // const score = await fetchScorePrompt(scorePayload).catch((err) =>
          //   console.log(err)
          // );

          // if (score) {
          //   setPromptScore(score.answer);
          // }
        } catch {
          setLastBotMessage({
            id: currentUUID,
            role: "bot",
            message:
              "Something went wrong during the chat. Please retry! If the error persists contact the customer service",
            isLoading: false,
            system_prompts: payload.system_prompt,
          });
        }
      } else {
        alert("Select a chatbot first.");
      }
    }
  };

  const sendConfigUpdate = () => {
    const configurationPayload = {
      chatbot_name: config.chatbot_name,
      chatbot_model_provider: config.chatbot_model_provider,
      chatbot_model: config.chatbot_model,
      temperature: config.temperature,
      max_tokens: config.max_tokens,
      chain_type: config.chain_type,
      system_prompt: config.system_prompt,
    };
    ManagerService.post(
      `companies/${localStorage.getItem("company_id")}/chatbots/${
        config.chatbot_name
      }/configuration`,
      configurationPayload
    )
      .then((res) => {
        toast.success("Configuration saved!");
      })
      .catch((e) => {
        console.log(e);
        toast.error("Something went wrong while saving the configuration.");
      });
  };

  useEffect(() => {
    listRef.current?.lastElementChild?.scrollIntoView();
  }, [messages]);

  useEffect(() => {
    const updatedMessages = messages.map((item) => {
      if (item.id === lastBotMessage.id) {
        return lastBotMessage;
      } else {
        return item;
      }
    });
    setMessages(updatedMessages);
  }, [lastBotMessage]);

  const handleSendFeedback = async (payload) => {
    // return index of message from messages array using payload.message_id
    const index = messages.findIndex((item) => item.id === payload.message_id);
    // get the previous message from messages array by index and store into query
    const query = messages[index - 1].message;

    payload.query = query;
    payload.user_sub = localStorage.getItem("sub");
    const res = await sendFeedback(config.chatbot_name, payload);
    if (res) {
      toast.success(
        "We received your feedback! Thanks to help Chatto to perform better. 😀 "
      );
    }
  };

  const handleClear = () => {
    setMessages([]);
    setHistory([]);
    setLastBotMessage("");
  };

  useEffect(() => {
    setConfig({});
  }, []);

  if (!user.is_admin && !user.is_builder) {
    return navigate("/chat");
  }

  return (
    <PageLayout>
      <div className="grid grid-cols-12">
        {/* playground - left side*/}
        <div className="w-full col-span-8 gap-y-3 h-screen relative gap-x-3 px-2">
          <div className="md:py-12 flex justify-between items-center">
            <h1 className="text-2xl font-bold">Playground</h1>
            <BaseButton
              value={"Save configuration"}
              onClick={() => sendConfigUpdate()}
              styleClass="bg-primary text-white px-4 text-sm lg:px-8"
            />
          </div>
          <div className="h-3/4 overflow-auto custom-webkit-scrollbar custom-webkit-scrollbar-track custom-webkit-scrollbar-thumb custom-webkit-scrollbar-thumb">
            <div ref={listRef}>
              {messages.map((message, i) => {
                return message.role === "user" ? (
                  <UserMessage
                    key={i}
                    message={message.message}
                    prompts={message.system_prompts}
                    onCopy={(q) => setQuery(q)}
                  />
                ) : (
                  <BotMessage
                    id={message.id}
                    key={i}
                    message={message.message}
                    sources={message.source}
                    prompts={message.system_prompts}
                    isLoading={message.isLoading}
                    onNegativeFeedback={(payload) =>
                      handleSendFeedback(payload)
                    }
                    onPositiveFeedback={(payload) =>
                      handleSendFeedback(payload)
                    }
                  />
                );
              })}
            </div>
          </div>
          {queryCount < 5000 ? (
            <div className="absolute bottom-3 w-full">
              <div className="flex flex-col gap-y-1">
                {/* <ChattoIsThinkingBadge showLoading={showLoading} /> */}
                <ChatInputPlayground
                  onClear={() => handleClear()}
                  onClick={handleSendMessage}
                  onChange={(e) => setQuery(e)}
                  query={query}
                  currentConfig={config}
                  onAdvancedSettingsChange={(advs) => setConfig(advs)}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* end playground - left side*/}

        {/* chat - right side */}
        <div className="bg-base col-span-4 px-5 h-screen py-1">
          <PlaygroundTools onChange={(nc) => setConfig(nc)} />
        </div>
        {/* end chat - right side */}
      </div>
    </PageLayout>
  );
}

export default Playground;
